exports.components = {
  "component---src-pages-2023-2024-js": () => import("./../../../src/pages/2023-2024.js" /* webpackChunkName: "component---src-pages-2023-2024-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bigandlittle-js": () => import("./../../../src/pages/bigandlittle.js" /* webpackChunkName: "component---src-pages-bigandlittle-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-officers-js": () => import("./../../../src/pages/officers.js" /* webpackChunkName: "component---src-pages-officers-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

